import Petter from './petter.png';
import GeirArne from './geirarne.png';
import JoVetle from './jovetle.png';
import Lasse from './lasse.png';
import Tomasz from './tomasz.png';
import Kristine from './kristine.png';
import Line from './line.png';
import Nebiue from './nebiue.png';

export default {
  Petter,
  GeirArne,
  JoVetle,
  Lasse,
  Tomasz,
  Kristine,
  Line,
  Nebiue,
};
