import React from 'react';
import styles from './styles.module.scss';
import Olkweb4Header from '../Olkweb4Page/Header/Olkweb4Header';
import Olkweb4HeaderAndFrontImage from '../../components/Olkweb4HeaderAndFrontImage/Olkweb4HeaderAndFrontImage';
import ArticleFooter from '../../components/ArticleFooter/ArticleFooter';
import ArticleUniversalDesign from '../../utils/ArticleImages/ArticleUniversalDesign.png';
import AritcleUniversalDesignMobile from '../../utils/ArticleImages/ArticleUniversalDesignMobile.png';
import useWindowDimensions from '../../services/useWindowDimensions';

const Olkweb4UniversalDesignPage: React.FC = () => {
  const isMobile = useWindowDimensions().width <= 550;

  const renderImage = () => {
    if (isMobile) {
      return AritcleUniversalDesignMobile;
    } else {
      return ArticleUniversalDesign;
    }
  };

  return (
    <div className={styles.articleContainer}>
      <Olkweb4Header />
      <div className={styles.olkweb4ArticleFront}>
        <div className={styles.center}>
          <Olkweb4HeaderAndFrontImage
            headline='Universell utforming fra start ^'
            imgsrc={renderImage()}
          />
        </div>
      </div>
      <div className={styles.articleTextContainer}>
        <div className={styles.articleContent}>
          <div>
            <p className={styles.boldText}>
              Ved å lage produkter som er universelt utformet gir vi alle i
              samfunnet like muligheter, uavhengig av personenes alder,
              funksjonsevne eller utdanningsnivå. Det er derfor vi i arbeidet
              med å utvikle Olkweb 4 har universell utforming som en selvsagt
              del av prosessen - allerede fra start.
            </p>
          </div>
          <div>
            <h2>Tverrfaglig team</h2>
            <p>
              Forskriften om universell utforming av ikt-løsninger lener seg på
              at nettløsninger skal utformes i samsvar med 48 minimumskrav.
              Kravene er hentet fra Web Content Accessibility Guidelines (WCAG),
              og omhandler temaene «Mulig å oppfatte», «Mulig å betjene»,
              «Forståelig» og «Robust». For eksempel er det krav om hvordan
              brukerne skal kunne navigere seg på siden, hvordan et skjema skal
              kunne brukes eller hvordan innholdet tilpasses på ulike
              skjermstørrelser. Dette for å sikre at alle brukere, med eller
              uten hjelpemidler, skal kunne benytte løsningen.
            </p>
          </div>
          <div>
            <p>
              Ved å ha et tverrfaglig team, bestående av både designere og
              utviklere, sikrer vi at universell utforming blir opprettholdt
              gjennom hele utviklingen av Olkweb 4. I designprosessen tar våre
              designere avgjørelser om for eksempel hvilken farge teksten skal
              ha i forhold til bakgrunnsfargen, slik at du som bruker skal kunne
              bruke løsningen uavhengig om du er fargeblind eller sitter på en
              mobil i sterkt sollys.
            </p>
          </div>
          <div>
            <p>
              Under utvikling av Olkweb 4 tar også utviklerne tekniske valg,
              slik at løsningen møter kravene. Som for eksempel ser de til at
              brukerne skal kunne nå alle klikkbare deler uten å måtte bruke en
              datamus. For å sikre at vi gjør de riktige valgene gjennomfører vi
              også ulike tester gjennom utviklingsløpet -slik at vi hele tiden
              jobber mot målet om et universelt utformet produkt.
            </p>
          </div>
          <div>
            <h2>Pågående prosess</h2>
            <p>
              Som Uutilsynet selv sier:{' '}
              <a
                className={styles.mail}
                href='https://www.uutilsynet.no/regelverk/universell-utforming-i-utviklingsprosessen/201#kontroller_jevnlig'
              >
                «Et nettsted blir aldri ferdig»
              </a>
              , så vet vi at vår jobb ikke er ferdig etter lanseringen av Olkweb
              4. Vi vil derfra teste og forbedre produktet, slik at vi hele
              tiden jobber mot målet om et produkt som alle kan benytte.
            </p>
          </div>
        </div>
      </div>
      <ArticleFooter />
    </div>
  );
};

export default Olkweb4UniversalDesignPage;
