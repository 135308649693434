import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  MainPage,
  PrivacyPage,
  NewsPage,
  Olkweb4Page,
  Olkweb4SecurityPage,
  Olkweb4UserExperiencePage,
  Olkweb4UniversalDesignPage,
  RequestDeleteAccountPage,
} from '../pages';

const Router = (): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <Routes>
      <Route path='/' element={<MainPage />} />
      <Route path='/olkweb4' element={<Olkweb4Page />} />
      <Route path='/olkweb4/security' element={<Olkweb4SecurityPage />} />
      <Route path='/deleteuser' element={<RequestDeleteAccountPage />} />
      <Route
        path='/olkweb4/userexperience'
        element={<Olkweb4UserExperiencePage />}
      />
      <Route
        path='/olkweb4/universaldesign'
        element={<Olkweb4UniversalDesignPage />}
      />
      <Route path='/privacy' element={<PrivacyPage />} />
      <Route path='/news' element={<NewsPage />} />
    </Routes>
  );
};

export default Router;
