import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Typography } from '@material-ui/core';
import jsStyles from './jsStyles';
import mainSectionImage from '../../utils/pictures/Olkweb.png';
import CountUp from 'react-countup';
import { ButtonLink } from '..';
import { getOlkwebData } from '../../services/Api';
import { IStats } from '../../interfaces/OlkwebStats';

const InfoSection = (): JSX.Element => {
  const [stats, setStats] = useState<IStats>({
    users: 0,
    offices: 0,
    apprentices: 0,
    companies: 0,
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const { data } = await getOlkwebData();
        setStats(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStats();
  }, []);

  const classes = jsStyles();

  return (
    <div className={styles.section1}>
      <div className={styles.sectionContent}>
        <div className={styles.middleSection}>
          <div className={styles.textSection}>
            <div className={styles.textSectionHeader}>
              <p className={styles.headerTitle}>Olkweb</p>
            </div>
            <div className={styles.textSectionDescription}>
              <p className={styles.mainDescText}>
                Et nettbasert verktøy for oppfølging, opplæring og
                administrasjon av lærlinger
              </p>
            </div>
            <div className={styles.mainSectionButtons}>
              <div className={styles.underline}>
                <ButtonLink link='#ContactSection' label='Book demo' />
              </div>
              <div className={styles.underline}>
                <ButtonLink
                  link='https://v3.olkweb.no/'
                  label='Logg inn Olkweb'
                />
              </div>
            </div>
          </div>
          <div className={styles.pictureSection}>
            <img
              className={styles.mainSectionImage}
              src={mainSectionImage}
              alt=''
            />
          </div>
        </div>
      </div>
      <div className={styles.mainSectionFooter}>
        <div className={styles.SectionFooter}>
          <div className={styles.leftContainer}>
            <div className={styles.numbersColorContainer} />
          </div>
          <div className={styles.middleContainer}>
            <div className={styles.numbersMiddleContainer}>
              <div className={styles.numbersGrid}>
                <div className={styles.apprenticeOffice}>
                  <Typography className={classes.olkStatNumber}>
                    <CountUp start={0} end={stats.offices} />
                  </Typography>
                  <Typography className={classes.olkStatText}>
                    Opplæringskontor
                  </Typography>
                </div>
                <div className={styles.dividerContainer}>
                  <div className={styles.divider} />
                </div>
                <div className={styles.activeUsers}>
                  <Typography className={classes.olkStatNumber}>
                    <CountUp start={0} end={stats.users} />
                  </Typography>
                  <Typography className={classes.olkStatText}>
                    Aktive brukere
                  </Typography>
                </div>
                <div className={styles.dividerContainer}>
                  <div className={styles.divider} />
                </div>
                <div className={styles.activeApprentice}>
                  <Typography className={classes.olkStatNumber}>
                    <CountUp start={0} end={stats.apprentices} />
                  </Typography>
                  <Typography className={classes.olkStatText}>
                    Aktive lærlinger
                  </Typography>
                </div>
                <div className={styles.dividerContainer}>
                  <div className={styles.divider} />
                </div>
                <div className={styles.apprenticeshipCompanies}>
                  <Typography className={classes.olkStatNumber}>
                    <CountUp start={0} end={stats.companies} />
                  </Typography>
                  <Typography className={classes.olkStatText}>
                    Lærebedrifter
                  </Typography>
                </div>
                <div className={styles.dividerContainer2}>
                  <div className={styles.divider2} />
                </div>
                <div className={styles.dividerContainer2}>
                  <div className={styles.divider2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
