import React from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import styles from './styles.module.scss';

type ButtonProps = {
  label: string;
  link: string;
};

const ButtonLink = ({ label, link }: ButtonProps): JSX.Element => {
  const appInsights = useAppInsightsContext();

  const trackBtn = () => {
    appInsights.trackEvent({ name: `"${label}" button clicked` });
  };

  return (
    <a className={styles.Button} href={link} onClick={trackBtn}>
      {label}
    </a>
  );
};

export default ButtonLink;
