import { makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles({
  header: {
    maxWidth: '19ch',
    fontSize: '30px',
    fontFamily: 'Almarena-DisplayLight',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '33px',
    paddingRight: '12px',
    '@media screen and (max-width: 550px)': {
      fontSize: '24px',
      lineHeight: '26.4px',
      width: '276px',
    },
    '@media screen and (max-width: 390px)': {
      fontSize: '20px',
      lineHeight: '22px',
    },
  },
  body: {
    fontSize: '20px',
    maxWidth: '30ch',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    marginTop: 0,
    paddingRight: '12px',
    lineHeight: '28px',
    '@media screen and (max-width: 550px)': {
      fontSize: '18px',
      lineHeight: '25px',
    },
    '@media screen and (max-width: 390px)': {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
});

export default jsStyles;
