import React from 'react';
import styles from './styles.module.scss';

type ButtonProps = {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  logo: boolean;
};

const StyledButton = ({ label, onClick }: ButtonProps): JSX.Element => {
  return (
    <button type='button' onClick={onClick} className={styles.Button}>
      <p className={styles.buttonText}>{label}</p>
    </button>
  );
};

export default StyledButton;
