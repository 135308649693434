import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './services/appInsights';
import Router from './router/Router';
import MainTheme from './styles/MainTheme';
import styles from './App.module.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const App = (): JSX.Element => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider theme={MainTheme}>
        <GoogleReCaptchaProvider reCaptchaKey='6LcxuqMpAAAAAOwAvxwUDZrj0Vn5jrqFdObxXkKp'>
          <div className={styles.app}>
            <Router />
          </div>
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
