import { TextField, styled } from '@mui/material';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#BABABA',
  },
  '& .MuiInputLabel-root': {
    color: '#BABABA',
    '&.Mui-error': {
      color: '#F6A09C',
    },
  },

  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      border: '1px solid #FFF',
    },
    '&:hover fieldset': {
      borderWidth: '2px',
      borderColor: '#FFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFF',
    },
    '&.Mui-error': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#F6A09C',
      },
      '& fieldset': {
        borderColor: '#F6A09C',
      },
      '&:hover fieldset': {
        border: '2px solid #F6A09C',
      },
    },
  },
  '& .MuiFormHelperText-root': {
    '&.Mui-error': {
      color: '#F6A09C',
    },
  },
  '& .MuiFormLabel-asterisk': {
    '&.Mui-error': {
      color: '#F6A09C',
    },
  },
});

export default CssTextField;
