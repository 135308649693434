import React from 'react';
import styles from './styles.module.scss';
import macbook from '../../../../utils/pictures/macbook.png';
import Point from './Point';
import checked from '../../../../utils/icons/devPhase/checked.svg';
import bullseye from '../../../../utils/icons/devPhase/bullseye.svg';
import arrow from '../../../../utils/icons/devPhase/arrow.svg';

const DevPhase = () => (
  <section className={styles.section}>
    <img
      className={styles.img}
      src={macbook}
      alt='En MacBook med visning av tilskuddsberegning fra en kandidat i Olkweb'
    />
    <div className={styles.text}>
      <h2 className={styles.h2}>Olkweb er i en intens utviklingsfase</h2>
      <ul className={styles.ul}>
        <Point
          icon={checked}
          alt='Sjekket av'
          text={
            <>
              Første <span>testrunde med reelle brukere</span> er gjennomført.
            </>
          }
        />
        <Point
          icon={bullseye}
          alt='Punkt'
          text={
            <>
              Ny runde med <span>brukertester</span> gjennomføres i
              <span> oktober.</span>
            </>
          }
        />
        <Point
          icon={bullseye}
          alt='Punkt'
          text={
            <>
              Q1 2025 benyttes til
              <span> kvalitetssikring </span>
              og justeringer basert på
              <span> brukertester </span>
              og
              <span> tilbakemeldinger.</span>
            </>
          }
        />
        <a href='#Questions' className={styles.a}>
          <span className={styles.span}>
            Les mer om overgangen til Olkweb 4.0
          </span>
          <img className={styles.arrow} alt='Pil' src={arrow} />
        </a>
        {/* Semantisk juks */}
      </ul>
    </div>
  </section>
);

export default DevPhase;
