import React from 'react';
import styles from './styles.module.scss';

interface IProps {
  icon: string;
  heading: string;
  subtext: string;
}

export const Keyword = ({ icon, heading, subtext }: IProps) => (
  <li>
    <img src={icon} className={styles.img} />
    <div className={styles.text}>
      <h3 className={styles.h3}>{heading}</h3>
      <p className={styles.p}>{subtext}</p>
    </div>
  </li>
);

export default Keyword;
