import React from 'react';
import styles from './styles.module.scss';
import {
  Header,
  AboutSection,
  InfoSection,
  AppSection,
  Footer,
  ContactForm,
  MainPageNewsSection,
} from '../../components';

const MainPage = (): JSX.Element => {
  return (
    <div className={styles.mainPage}>
      <Header />
      <InfoSection />
      <AboutSection />
      <AppSection />
      <MainPageNewsSection />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default MainPage;
