import React from 'react';
import styles from './styles.module.scss';
import Nyheter from '../../utils/pictures/NyBrukerflate.png';

const NewsSection = (): JSX.Element => {
  return (
    <div id='NewsSection' className={styles.newsSection}>
      <div className={styles.newsContent}>
        <div className={styles.newsHeadline}>
          <div className={styles.line} />
          <h2>Nyheter^</h2>
          <div className={styles.line} />
        </div>
        <div className={styles.recentNewsBox}>
          <div className={styles.newsBackground}>
            <div className={styles.newsImgBox}>
              <img className={styles.newsImg} src={Nyheter} alt='' />
            </div>
            <div className={styles.recentNewsText}>
              <h1 className={styles.recentHeader}>
                Ny brukerflate for lærlinger i betatest
              </h1>
              <h3>31.08.23</h3>
              <p>
                Ny brukerflate for lærlinger er nå i betatest hos utvalgte
                opplæringskontor. Dette markerer det første steget i en
                omfattende utviklingsprosess for Olkweb. Den nye brukerflaten er
                web-basert som før, og fungerer på både pc, nettbrett og mobil.
                Vi har designet den med fokus på å forbedre opplevelsen, og ser
                frem til å motta verdifulle tilbakemeldinger fra brukerne i
                betatestfasen.
              </p>
              <p>
                Etter planen vil den nye brukerflaten åpnes for alle lærlinger
                senere i høst. Dette gir dem muligheten til å dra nytte av de
                forbedrede funksjonene og den forbedrede opplevelsen som den nye
                brukerflaten bringer med seg.
              </p>
              <p>
                Dette prosjektet representerer vår forpliktelse til å
                kontinuerlig forbedre og tilpasse våre tjenester for å møte
                brukernes behov. Vi setter stor pris på engasjementet og støtten
                fra våre brukere, og ser frem til å dele ytterligere
                oppdateringer og forbedringer i tiden som kommer.
              </p>
              <p>
                Sammen tar vi det første steget mot en mer optimal og
                brukervennlig opplevelse.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.line} />
        {/* <div className={styles.accordionContainer}>
          <BasicAccordion
            NewsHeaderText='Nr 1'
            NewsText='Halla gekko'
            DateText='23.06.23'
          />
        </div>
        <div className={styles.line} /> */}
      </div>
    </div>
  );
};

export default NewsSection;
