import React from 'react';
import { Typography } from '@material-ui/core';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import mobileAppImage from '../../utils/pictures/mobileAppImage1.png';
import playStoreBadge from '../../utils/badges/playStoreBadgeNO.png';
import AppStoreBadge from '../../utils/badges/appStoreBadgeNO.svg';
import styles from './styles.module.scss';
import jsStyles from './jsStyles';

const AppSection = (): JSX.Element => {
  const classes = jsStyles();
  const appInsights = useAppInsightsContext();

  const trackAppStore = () => {
    appInsights.trackEvent({ name: 'AppStore clicked' });
  };

  const trackPlayStore = () => {
    appInsights.trackEvent({ name: 'PlayStore clicked' });
  };

  return (
    <div id='AppSection' className={styles.appSection}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.infoContainer}>
            <div className={styles.textContainer}>
              <Typography className={classes.header}>
                Mobilapp for lærlingene ^
              </Typography>
              <Typography className={classes.body}>
                Gjør det enkelt for lærlingene å dokumentere læretiden sin med
                Olkweb Lærling.
              </Typography>
              <Typography className={classes.body}>
                Med mobilappen kan lærlingene dokumentere direkte fra mobil
                eller nettbrett med bilde, tekst og video. De får i tillegg
                oversikt over både kommende hendelser, relasjoner, meldinger og
                mye mer!
              </Typography>
              <Typography className={classes.body}>
                Olkweb Lærling finnes på Google Play og App Store.
              </Typography>
            </div>
            <div className={styles.btnContainer}>
              <a
                onClick={trackAppStore}
                href='https://apps.apple.com/no/app/olkweb-l%C3%A6rling/id1504430766'
              >
                <img src={AppStoreBadge} alt='Last ned fra Goolge Play' />
              </a>
              <a
                onClick={trackPlayStore}
                href='https://play.google.com/store/apps/details?id=com.olkwebapprn&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
              >
                <img src={playStoreBadge} alt='Last ned fra Goolge Play' />
              </a>
            </div>
          </div>
          <div className={styles.imgContainer}>
            <img
              className={styles.mobileAppImage}
              src={mobileAppImage}
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSection;
