import useWindowDimensions from '../../../../services/useWindowDimensions';
import styles from './styles.module.scss';
import React from 'react';

function Olkweb4Page() {
  return (
    <section className={styles.section}>
      <img
        className={styles.background}
        src={
          useWindowDimensions().width > 700
            ? '/img/Olkweb4/Hero.desktop.jpg'
            : '/img/Olkweb4/Hero.phone.jpg'
        }
        alt='Olkweb 4.0 lansering i april 2025'
      />
    </section>
  );
}

export default Olkweb4Page;
