import React from 'react';
import styles from './styles.module.scss';
import ArribatecLogo from '../../utils/icons/OlkwebArribatecProductLogoWhite.svg';
import DeleteAccountContactSection from '../../components/DeleteAccountContactSection/DeleteAccountContactSection';

const RequestDeleteAccountPage = (): JSX.Element => {
  return (
    <div className={styles.requestDeleteAccountPage}>
      <header>
        <img
          src={ArribatecLogo}
          className={styles.headerLogo}
          alt='Olkweb Logo'
        />
      </header>

      <main className={styles.deleteUserContent}>
        <h1 className={styles.headline}>
          Ønsker du å slette brukeren din i Olkweb?
        </h1>
        <p className={styles.infoText}>
          Dersom du ønsker å få slettet din bruker i Olkweb, fyll ut skjemaet
          under. Forespørselen din oversendes til det aktuelle
          opplæringskontoret du er registrert hos. Det er opp til dem å vurdere
          om brukerdataene kan slettes eller ikke. Merk at ved sletting vil alle
          dine data i Olkweb slettes for godt, med unntak av anonymiserte data
          til bruk i statistikk (alder, lærefag, kandidattype, periode).
        </p>

        <DeleteAccountContactSection />
      </main>
      <footer className={styles.deleteAccountFooter}>
        <a href='/' className={styles.goToOlkweb}>
          Gå til olkweb.no
        </a>
      </footer>
    </div>
  );
};

export default RequestDeleteAccountPage;
