import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import jsStyles from './jsStyles';

type CardProps = {
  fullName: string;
  jobTitle: string;
  imgSrc: string;
};

const PersonCard = ({ fullName, jobTitle, imgSrc }: CardProps): JSX.Element => {
  const classes = jsStyles();
  return (
    <div className={styles.card}>
      <img src={imgSrc} alt={`${fullName} - Picture`} className={styles.img} />
      <Typography className={classes.name}>{fullName}</Typography>
      <Typography className={classes.jobTitle}>{jobTitle}</Typography>
    </div>
  );
};

export default PersonCard;
