import React from 'react';
import styles from './styles.module.scss';
import phoneImage from '../../../../utils/pictures/Olkweb4Page/2.png';
import Keyword from './Keyword';
import notif from '../../../../utils/icons/news/notif.svg';
import doc from '../../../../utils/icons/news/doc.svg';
import book from '../../../../utils/icons/news/book.svg';
import phone from '../../../../utils/icons/news/phone.svg';
import highFive from '../../../../utils/icons/news/high-five.svg';
import { uniqueKey } from '../../../../utils/functions';

const News = () => (
  <section className={styles.section}>
    <div className={styles.content}>
      <div className={styles.text}>
        <p className={styles.p}>
          Nye Olkweb vil ha alle dagens funksjoner, men i tillegg kommer
        </p>
        <h2 className={styles.h2}>Nyheter i Olkweb 4.0</h2>
        <ul className={styles.ul}>
          <Keyword
            icon={notif}
            heading='Bli varslet på det som skjer'
            subtext='Velg mellom e-post, SMS og push-varslinger.'
            key={uniqueKey()}
          />
          <Keyword
            icon={doc}
            heading='Innlesing av filer fra VIGO'
            subtext='Opprett nye brukere eller les inn tilskuddsrapporten fra VIGO.'
            key={uniqueKey()}
          />
          <Keyword
            icon={book}
            heading='Opplæringsbok'
            subtext='En fleksibel internplan som kan bygges av både kompetansemål og arbeidsoppgaver.'
            key={uniqueKey()}
          />
          <Keyword
            icon={phone}
            heading='Mobiltilpasset for alle brukergrupper'
            subtext='Alle brukerflater tilpasset store og små skjermer.'
            key={uniqueKey()}
          />
          <Keyword
            icon={highFive}
            heading='Integrasjoner mot relevante parter'
            subtext='Spar tid med effektive integrasjoner mot relevante parter, som f.eks. Exchange (Outlook).'
            key={uniqueKey()}
          />
        </ul>
      </div>
      <img
        src={phoneImage}
        alt='Mobiltelefon med visning av opplæringsbok i Olkweb'
        className={styles.phone}
      />
    </div>
  </section>
);

export default News;
