import React from 'react';
import styles from './styles.module.scss';

interface IProps {
  icon: string;
  alt: string;
  text: JSX.Element;
}

const Point = ({ icon, alt, text }: IProps) => (
  <li className={styles.li}>
    <img src={icon} className={styles.img} />
    <p className={styles.p}>{text}</p>
  </li>
);

export default Point;
