import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles.module.scss';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CircularProgress } from '@mui/material';
import { IDeleteUserForm } from '../../interfaces/DeleteUserForm';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { postHaloDeleteUser } from '../../services/Api';
import StyledButton from '../../components/StyledButton/StyledButton';
import CssTextField from '../../components/CssTextField/CssTextField';

const DeleteAccountContactSection = (): JSX.Element => {
  const appInsights = useAppInsightsContext();
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>('');
  const [hasInteracted, setHasInteracted] = useState(false);
  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const [formData, setFormData] = useState<IDeleteUserForm>({
    email: '',
    name: '',
    phone: '',
    message: '',
    reCaptchaToken: '',
  });

  const validationMessages = {
    nameRequired: 'Fyll inn navn.',
    phoneRequired: 'Fyll inn telefonnummer.',
    phone: 'Telefonnummer kan kun innholde tall.',
    phoneLength: 'Fyll inn telefonnummer med 8 sifre.',
    emailRequired: 'Fyll inn gyldig e-postadresse.',
    confirm: 'Takk for din henvendelse.',
    error:
      'Det oppsto et problem, prøv igjen eller send en e-post til kundeservice@arribatec.com',
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {
    const data = {
      email: formData.email,
      name: formData.name,
      phone: formData.phone,
      message: formData.message,
      reCaptchaToken: formData.reCaptchaToken,
    };
    if (field === 'name') data.name = e.target.value;
    if (field === 'email') data.email = e.target.value;
    if (field === 'phone') data.phone = e.target.value;
    if (field === 'message') data.message = e.target.value;
    if (!hasInteracted) {
      setHasInteracted(true);
    }
    setFormData(data);
  };

  // ---- Error message resetting ----

  useEffect(() => {
    if (formData.name) setNameError('');
  }, [formData.name]);

  useEffect(() => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    /* ^^^Chcecking for atleast one of the following letters, numbers or symbols + @ + atleast one of the following letters, numbers or ".-" + . and atleast 2 letters*/

    if (!isFocused) {
      if (formData.email === '') {
        setEmailError('');
      } else if (emailRegex.test(formData.email)) {
        setEmailError('');
      } else {
        setEmailError(validationMessages.emailRequired);
      }
    }
  }, [formData.email, isFocused, validationMessages.emailRequired]);

  useEffect(() => {
    if (!isFocused) {
      if (formData.phone === '') setPhoneError('');
      if (formData.phone !== '' && isNaN(Number(formData.phone))) {
        setPhoneError(validationMessages.phone);
      } else {
        if (formData.phone !== '' && formData.phone.length < 8) {
          setPhoneError(validationMessages.phoneLength);
        } else {
          setPhoneError('');
        }
      }
    }
  }, [
    formData.phone,
    isFocused,
    validationMessages.phone,
    validationMessages.phoneLength,
  ]);

  const handleSubmit = async (reCaptchaToken: string) => {
    setFeedback('');
    //Form verification
    if (!formData.name) setNameError(validationMessages.nameRequired);
    if (!formData.email) setEmailError(validationMessages.emailRequired);
    if (!formData.phone) setPhoneError(validationMessages.phoneRequired);

    if (isNaN(Number(formData.phone))) {
      setPhoneError(validationMessages.phone);
      return;
    }
    if (formData.phone !== '' && formData.phone.length < 8) return;
    if (!formData.name || !formData.email || !formData.phone) {
      return;
    }

    //Form submission
    try {
      setLocalLoading(true);
      const data = {
        email: formData.email,
        name: formData.name,
        phone: formData.phone,
        message: formData.message,
        reCaptchaToken: reCaptchaToken,
      };
      setFormData(data);
      await postHaloDeleteUser(data);
      setFormSubmitted(true);
    } catch (e) {
      setFormSubmitted(false);
      setFeedback(validationMessages.error);
    } finally {
      setTimeout(function () {
        setLocalLoading(false);
      }, 500);
      appInsights.trackEvent({ name: '"Send inn skjema" button clicked' });

      // State resetting ?? Only after the form is submitted

      if (formSubmitted === true) {
        const data = {
          email: '',
          name: '',
          phone: '',
          message: '',
          reCaptchaToken: reCaptchaToken,
        };
        setFormData(data);
      }
    }
  };

  const handleBackToForm = () => {
    setFormSubmitted(false);
    appInsights.trackEvent({ name: '"Tilbake til skjema" button clicked' });
  };

  const ReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        setFeedback(validationMessages.error);
        return;
      }

      const token = await executeRecaptcha('contactForm');
      handleSubmit(token);
    }, [executeRecaptcha]);

    return (
      <div className={styles.formButtonContainer}>
        <StyledButton
          label='Send kontaktskjema'
          onClick={handleReCaptchaVerify}
          logo
        ></StyledButton>
      </div>
    );
  };

  const FormSent = (): JSX.Element | null => {
    if (formSubmitted) {
      return (
        <div className={styles.feedback}>
          <p>{validationMessages.confirm}</p>
          <StyledButton
            logo={true}
            onClick={handleBackToForm}
            label='Tilbake til kontaktskjemaet'
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {/* As long as the from is not subbmitted and is not set to loading the form will be visable */}
      {!formSubmitted && !localLoading ? (
        <form className={styles.deleteUserForm}>
          <p className={styles.requiredText}>
            Obligatoriske felter er merket med *
          </p>
          <CssTextField
            required
            label='Navn'
            type='text'
            inputProps={{
              inputMode: 'text',
              maxLength: 50,
            }}
            fullWidth
            onChange={(e) => handleChange(e, 'name')}
            error={nameError !== ''}
            helperText={nameError}
          />
          <CssTextField
            required
            label='E-post'
            type='text'
            inputProps={{
              maxLength: 80,
            }}
            fullWidth
            onChange={(e) => handleChange(e, 'email')}
            error={emailError !== ''}
            helperText={emailError}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <CssTextField
            required
            label='Telefonnummer'
            type='tel'
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 15,
            }}
            fullWidth
            onChange={(e) => handleChange(e, 'phone')}
            error={phoneError !== ''}
            helperText={phoneError}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <CssTextField
            multiline
            minRows={3}
            maxRows={8}
            label='Melding'
            type='text'
            fullWidth
            onChange={(e) => handleChange(e, 'message')}
          />
          <p className={styles.errorMessage}>{feedback}</p>
          <ReCaptchaComponent />
        </form>
      ) : (
        <FormSent /> /* if the form is submitted then show FormSent */
      )}

      {/* Checking for local loading and that the form have not completed it's submitting then if that is true then start loading */}
      {localLoading &&
      !formSubmitted /* !formSubmitted is there to prevent the loading and FormSent component to apeare at the same time */ ? (
        <div className={styles.progressContainer}>
          <CircularProgress className={styles.progress} />
        </div>
      ) : null}
    </>
  );
};

export default DeleteAccountContactSection;
