const keys: string[] = [];
const chars: string[] = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
];

export function uniqueKey(length?: number): string {
  let key = randomKey(length);

  while (keys.includes(key)) key = randomKey(length);

  keys.push(key);

  return key;
}

export function randomKey(length?: number): string {
  let key = '';

  for (let i = 0; i < (length ?? 20); i++)
    key += chars[Math.floor(Math.random() * chars.length)];

  return key;
}

export default { uniqueKey, randomKey };
