import React from 'react';
import styles from './styles.module.scss';
import Card from './Card';
import Security from '../../../../utils/pictures/Olkweb4Page/Security.svg';
import UserCenter from '../../../../utils/pictures/Olkweb4Page/UserCenter.svg';
import UU from '../../../../utils/pictures/Olkweb4Page/UU.svg';

const MoreAbout = () => (
  <section className={styles.section}>
    <div className={styles.div}>
      <h2 className={styles.h2}>Mer om Olkweb 4</h2>
      <ul className={styles.ul}>
        <Card
          img={Security}
          h='Sikkerhet er en sentral del'
          p='Med "Security by Design" er dine sensitive data trygge i Olkweb 4.'
          hrefText='Les om hvordan vi jobber med sikkerhet'
          href='/olkweb4/security'
        />
        <Card
          img={UserCenter}
          h='Med brukeren i sentrum'
          p='Et av målene med Olkweb 4 er å gi brukerne en enklere arbeidshverdag.'
          hrefText='Les om hvordan vi jobber med brukeropplevelse'
          href='/olkweb4/userexperience'
        />
        <Card
          img={UU}
          h='Universell utforming fra start'
          p='Ved å lage produkter som er universelt utformet gir vi alle i samfunnet like muligheter'
          hrefText='Les om hvordan vi jobber med universell utforming'
          href='/olkweb4/universaldesign'
        />
      </ul>
    </div>
  </section>
);

export default MoreAbout;
