import React from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as ArribatecLogo } from '../../../utils/icons/LogoFooter.svg';
import styles from './styles.module.scss';
import jsStyles from './jsStyles';

const Olkweb4Footer = (): JSX.Element => {
  const classes = jsStyles();
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <ArribatecLogo fill='black' className={styles.logo} />
        <Typography className={classes.text}>
          For utviklingen av Olkweb har Arribatec bred erfaring med:
        </Typography>
        <ul className={styles.ul}>
          <li>
            <Typography className={classes.text}>
              Sikkerhet i applikasjonsutvikling
            </Typography>
          </li>
          <li>
            <Typography className={classes.text}>
              Produktutvikling og forvaltning
            </Typography>
          </li>
          <li>
            <Typography className={classes.text}>Support</Typography>
          </li>
          <li>
            <Typography className={classes.text}>
              Cloud, servere, backup og nettverk (egne datasentere i Norge)
            </Typography>
          </li>
        </ul>
        <Typography className={classes.text}>
          Les mer om Arribatec sin ekspertise på{' '}
          <a href='https://www.arribatec.com/' className={styles.link}>
            www.arribatec.com
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default Olkweb4Footer;
