import React from 'react';
import { uniqueKey } from '../../../../../utils/functions';
import styles from './styles.module.scss';

interface IProps {
  heading: string;
  children: string[];
}

const Section = ({ heading, children }: IProps) => (
  <section className={styles.section}>
    <h3 className={styles.h3}>{heading}</h3>
    {children.map(
      (i: string): JSX.Element => (
        <p className={styles.p} key={uniqueKey()}>
          {i}
        </p>
      ),
    )}
  </section>
);

export default Section;
