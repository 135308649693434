import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../utils/icons/logo.svg';
import { ReactComponent as BvIcon } from '../../utils/icons/link.svg';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles.module.scss';
import './mobileMenu.css';
import useWindowDimensions from '../../services/useWindowDimensions';
import { ButtonLink } from '..';
import { Link } from 'react-router-dom';

interface headerProps {
  noNav?: boolean;
}

const NewsHeader: React.FC<headerProps> = ({ noNav }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const isMobile = useWindowDimensions().width <= 1000;

  const handleFixedHeader = () => {
    let activeClass = styles.header;
    if (click) activeClass = styles.headerFixed;
    return activeClass;
  };
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.sticky}>
      <div className={handleFixedHeader()}>
        <div className={styles.navbar}>
          <div className={styles.logoNav}>
            <Link to={'/'} onClick={goToTop}>
              <Logo fill='white' />
            </Link>
          </div>

          {/* Desktop navbar / Menu */}
          {noNav ? null : (
            <div className={styles.desktopNavbar}>
              <ButtonLink label='Olkweb 4.0' link='olkweb4' />
              <ButtonLink label='Om Olkweb' link='/#AboutSection' />
              <div className={styles.currentPage}>
                <ButtonLink label='Nyheter' link='news' />
              </div>
              <ButtonLink label='Mobilapp' link='/#AppSection' />
              <ButtonLink label='Kontakt oss' link='/#ContactSection' />
              <ButtonLink
                label='Brukerveiledning'
                link='https://brukerveiledning.olkweb.no/'
              />
              <BvIcon stroke='white' className={styles.bvIcon} />
            </div>
          )}

          {/* Mobile navbar */}
          {noNav
            ? null
            : isMobile && (
                <div className={styles.mobileNavbar} onClick={handleClick}>
                  <p className={styles.navbarText}>Meny</p>
                  {click ? (
                    <div className={styles.navbarIcon}>
                      <CloseIcon fontSize='medium' />
                    </div>
                  ) : (
                    <div className={styles.navbarIcon}>
                      <MenuIcon fontSize='medium' />
                    </div>
                  )}
                </div>
              )}
        </div>
      </div>

      {/* Mobile menu */}
      {isMobile && (
        <div className={click ? 'mobile-menu active' : 'mobile-menu'}>
          <div onClick={closeMobileMenu} className={styles.mobileContainer}>
            <ButtonLink label='Olkweb 4.0' link='olkweb4' />
          </div>
          <div onClick={closeMobileMenu} className={styles.mobileContainer}>
            <ButtonLink label='Om OLKWEB' link='/#AboutSection' />
          </div>
          <div onClick={closeMobileMenu} className={styles.mobileContainer}>
            <div className={styles.currentPage}>
              <ButtonLink label='Nyheter' link='news' />
            </div>
          </div>
          <div onClick={closeMobileMenu} className={styles.mobileContainer}>
            <ButtonLink label='Mobilapp' link='/#AppSection' />
          </div>
          <div onClick={closeMobileMenu} className={styles.mobileContainer}>
            <ButtonLink label='Kontakt oss' link='/#ContactSection' />
          </div>
          <div onClick={closeMobileMenu}>
            <div className={styles.mobileContainer}>
              <ButtonLink
                label='Brukerveiledning'
                link='https://brukerveiledning.olkweb.no/'
              />
              <BvIcon stroke='white' className={styles.bvMobilIcon} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsHeader;
