import React from 'react';
import styles from './styles.module.scss';

interface IProps {
  img: string;
  name: string;
  position: string;
}

const Card = ({ img, name, position }: IProps) => (
  <li className={styles.li}>
    <img className={styles.img} src={img} />
    <h3 className={styles.h3}>{name}</h3>
    <p className={styles.p}>{position}</p>
  </li>
);

export default Card;
