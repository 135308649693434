import React from 'react';
import { Typography, Link } from '@material-ui/core';
import styles from './styles.module.scss';
import jsStyles from './jsStyles';
import { Header, Footer } from '../../components';

const PrivacyPage = (): JSX.Element => {
  const classes = jsStyles();
  return (
    <div className={styles.privacyPage}>
      <Header noNav={true} />
      <div className={styles.container}>
        <div className={styles.content}>
          <Typography className={classes.heading}>
            Personvernerklæring ^
          </Typography>
          <div>
            <Typography className={classes.body}>
              Arribatec Software Development (tidligere Innit AS), et selskap i
              Arribatec gruppen, respekterer din rett til personvern. Denne
              personvernerklæringen beskriver hvorfor vi samler inn informasjon
              om de, hvordan vi bruker denne informasjonen og hvordan vi tar
              hensyn til ditt personvern.
            </Typography>
            <Typography className={classes.body}>
              <Link href='https://www.arribatec.com'>Arribatec Norge AS</Link>{' '}
              er et internasjonalt konsern med ledelse og juridiske enheter som
              krysser landegrenser. Hovedkontoret til Arribatec ligger i Oslo og
              Arribatec -konsernet er underlagt europeisk personvernlovgivning,
              inkludert personvernforordningen (GDPR).
            </Typography>
            <Typography className={classes.body}>
              Olkweb ble utviklet i et samarbeid mellom opplæringskontorerer og
              Innit, og er et produkt som selges av Innit. Innit er en del av
              Arribatec gruppen og vil være en del av forretningsområde Business
              Services.
            </Typography>
            <Typography className={classes.body}>
              Henvendelser til Arribatec som databehandler kan rettes til
              Arribatec Support - Innit Kundeservice «kontaktskjema».
            </Typography>
          </div>

          <div>
            <Typography className={classes.subheading}>
              Om personvernerklæring og personopplysninger
            </Typography>
            <Typography className={classes.body}>
              Denne personvernerklæringen gjelder for alle forretningsprosesser
              i produkter, mobilløsninger, domener og skytjenester som benyttes
              til opplæringshensikt. Dette er gjeldende for Olkweb-relaterte
              løsninger, og tilleggsmodul Aspirant. Tjenestespesifikke
              bestemmelser finnes i bruksvilkårene for den aktuelle tjenesten,
              databehandleravtalen eller annen tilsvarende informasjon for den
              aktuelle tjenesten.
            </Typography>
            <Typography className={classes.body}>
              Denne personvernerklæringen gir informasjon om behandling av
              personopplysninger som Arribatec utfører på vegne av kundene våre
              ved support og videreutvikling av Olkweb og tilleggstjenester
              (kunden som behandlingsansvarlig og Arribatec som databehandler).
            </Typography>
            <Typography className={classes.body}>
              Personopplysninger er informasjon som kan identifisere deg som
              person, for eksempel fødsels- og personnummer, navn, e-post,
              gateadresse, telefonnummer eller lignende. Behandling av
              personopplysninger er nødvendig for at vi skal kunne levere gode
              tjenester.
            </Typography>
            <Typography className={classes.body}>
              For å tilby Olkweb som en tjeneste til våre kunder, innebærer det
              behandling av personopplysninger. Formålet med behandling av
              personopplysningene beskrives i tjenesteavtalen mellom Arribatec
              og kunde, og kunde er behandlingsansvarlig, Arribatec er
              databehandler og Arribatec behandler kun personopplysninger på
              vegne av kunde. Forholdet mellom kunde som behandlingsansvarlig og
              Arribatec som databehandler reguleres i en databehandleravtale.
            </Typography>

            <Typography className={classes.subheading2}>
              Formålet med behandlingen
            </Typography>
            <Typography className={classes.body}>
              Olkweb brukes til dokumentering og oppfølging av et opplæringsløp.
              Aspirant brukes til dokumentering av søkere og opptak til
              læreplass.
            </Typography>

            <Typography className={classes.subheading2}>
              Hvilke opplysninger som behandles
            </Typography>
            <Typography className={classes.body}>
              Følgende personopplysninger skal behandles: Personnavn,
              e-postadresse, telefonnummer, fødsels- og personnummer, adresse,
              opplæringsløp, halvårsvurderinger, relasjoner,
              dokumentasjonsinnlegg, ansettelsesforhold, lærefag,
              fagprøveresultat og CV.
            </Typography>
            <Typography className={classes.body}>
              Sensitive opplysninger som eksempelvis helseattester,
              politiattester o.l. dokumenter eller informasjon er Arribatec ikke
              ansvarlig for dersom systemet benyttes til lagring av disse. Det
              er da behandlingsansvarlig sitt ansvar, og Arribatec anbefaler at
              disse lenkes til/refereres til i Olkweb - fremfor at de lagres i
              systemet.
            </Typography>

            <Typography className={classes.subheading2}>
              Slik behandles dataene
            </Typography>
            <Typography className={classes.body}>
              Lagring av personopplysninger, og administrere data gjøres i
              samråd med Behandlingsansvarlig.
            </Typography>
          </div>

          <div>
            <Typography className={classes.subheading}>
              Slik brukes underleverandører i Olkweb
            </Typography>
            <Typography className={classes.body}>
              Arribatec benytter underleverandører til å behandle
              personopplysninger og kan i den forbindelse eksportere kundedata
              til andre selskaper innenfor EU. Underleverandører i Olkweb
              benyttes til utsending av e-post og sms. Arribatec inngår en
              databehandleravtale med underleverandører for å opprettholde
              personvern i henhold til gjeldende personvernlovgivning og for å
              oppfylle våre forpliktelser overfor kunder.
            </Typography>
            <Typography className={classes.body}>
              Arribatec benytter kun underleverandører for å tilby Olkweb på en
              trygg og kostnadsbevisst måte. Disse tredjepartene inkluderer:
            </Typography>
            <Typography className={classes.body}>
              Link Mobility AS brukes som leverandør av SMS tjenester og mottar
              telefonnummeret og selve SMSen som skal sendes, for å kunne
              formidle dette videre til oppgitt nummer.
            </Typography>
            <Typography className={classes.body}>
              Mailgun Technologies sitt produkt Mailjet brukes ved formidling av
              e-post. Slike meldinger inneholder mottaker og avsender (navn og
              e-postadresse). Mailjet er ISO 27001 sertifisert og GDPR compliant
              og i henhold til Schrems II. I Mailjet sin personvernerklæring
              informeres det at lagring av data gjøres på en Google Cloud
              Platform i Tyskland og Belgia.
              (https://www.mailjet.com/security-privacy/) Arribatec har signert
              en Data Processing Agreement (https://www.mailjet.com/dpa/) med
              Mailgun som regulerer at Mailgun utfører sin tjeneste i henhold
              til GDPR og Europeisk lovverk gjennom at det inngås gyldige
              overføringsmekanismer når data overføres til andre land utenfor
              EU.
            </Typography>

            <Typography className={classes.subheading2}>
              Arribatec’ forpliktelse
            </Typography>
            <Typography className={classes.body}>
              Arribatec er forpliktet til å samarbeide med sine kunder for å
              sikre personvernet til de registrerte personene. Arribatec skal gi
              den informasjonen som er nødvendig for at kunden skal kunne
              overholde gjeldende personvernlovgivning.
            </Typography>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPage;
