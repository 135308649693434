import React from 'react';
import styles from './styles.module.scss';
import Card from './Card';
import img from '../../../../utils/pictures';

const Team = () => (
  <section className={styles.section}>
    <div className={styles.div}>
      <h2 className={styles.h2}>
        Et stort team med lang erfaring i brukerfokusert utvikling
      </h2>
      <p className={styles.p}>
        I Arribatec har vi tilgang på et stort antall erfarne spesialister innen
        utvikling, brukertesting og sikkerhet. Møt laget som nå utvikler en helt
        ny versjon:
      </p>
      <ul className={styles.ul}>
        <Card
          img={img.Petter}
          name='Petter Bru'
          position='Senior prosjektleder'
        />
        <Card
          img={img.GeirArne}
          name='Geir Arne Mo Bjørnseth'
          position='Senior utvikler (Security Lead)'
        />
        <Card
          img={img.JoVetle}
          name='Jo Vetle Albrethsen'
          position='Backend-utvikler'
        />
        <Card
          img={img.Lasse}
          name='Lasse Folkestad Soltvedt'
          position='Senior utvikler'
        />
        <Card
          img={img.Tomasz}
          name='Tomasz Rudowski'
          position='Senior utvikler'
        />
        <Card
          img={img.Kristine}
          name='Kristine Lundquist'
          position='UX team lead'
        />
        <Card img={img.Nebiue} name='Nebiue Yonas Gidey' position='Utvikler' />
        <Card img={img.Line} name='Line Løver Urdalen' position='UX-designer' />
      </ul>
    </div>
  </section>
);

export default Team;
