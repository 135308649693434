import React, { useEffect, useState, useCallback } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { MenuItem, CircularProgress } from '@mui/material';
import { postHaloSubmission } from '../../services/Api';
import { IFormData } from '../../interfaces/ContactForm';
import { StyledButton } from '..';
import styles from './styles.module.scss';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import MainTheme from '../../styles/MainTheme';
import { ThemeProvider } from '@mui/material/styles';
import CssTextField from '../CssTextField/CssTextField';

const ContactForm = (): JSX.Element => {
  const appInsights = useAppInsightsContext();
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>('');
  const [hasInteracted, setHasInteracted] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [categoryError, setCategoryError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [formData, setFormData] = useState<IFormData>({
    category: '',
    email: '',
    name: '',
    phone: '',
    message: '',
    reCaptchaToken: '',
  });

  const categories = [
    'Ønsker demo av Olkweb',
    'Prisforespørsel Olkweb',
    'Kundeservice',
  ];

  const validationMessages = {
    categoryRequired: 'Benytt et av valgene i listen.',
    nameRequired: 'Fyll inn navn.',
    phoneRequired: 'Fyll inn telefonnummer.',
    phone: 'Telefonnummer kan kun innholde tall.',
    phoneLength: 'Fyll inn telefonnummer med 8 sifre.',
    emailRequired: 'Fyll inn gyldig e-postadresse.',
    messageRequired: 'Skriv inn melding',
    confirm: 'Takk for din henvendelse.',
    error:
      'Det oppsto et problem, prøv igjen eller send en e-post til kundeservice@arribatec.com',
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {
    const data = {
      category: selectedCategory,
      email: formData.email,
      name: formData.name,
      phone: formData.phone,
      message: formData.message,
      reCaptchaToken: formData.reCaptchaToken,
    };
    if (field === 'category') setSelectedCategory(e.target.value);
    if (field === 'name') data.name = e.target.value;
    if (field === 'email') data.email = e.target.value;
    if (field === 'phone') data.phone = e.target.value;
    if (field === 'message') data.message = e.target.value;
    if (!hasInteracted) {
      setHasInteracted(true);
    }
    setFormData(data);
  };

  // ---- Error message resetting ----
  useEffect(() => {
    if (selectedCategory) setCategoryError('');
  }, [selectedCategory]);

  useEffect(() => {
    if (formData.name) setNameError('');
  }, [formData.name]);

  useEffect(() => {
    if (hasInteracted) {
      if (formData.email === '') {
        setEmailError('');
      } else if (formData.email.includes('@') && formData.email.includes('.')) {
        setEmailError('');
      } else {
        setEmailError(validationMessages.emailRequired);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.email, hasInteracted]);

  useEffect(() => {
    if (formData.phone === '') setPhoneError('');
    if (formData.phone !== '' && isNaN(Number(formData.phone))) {
      setPhoneError(validationMessages.phone);
    } else {
      if (formData.phone !== '' && formData.phone.length < 8) {
        setPhoneError(validationMessages.phoneLength);
      } else {
        setPhoneError('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.phone]);

  useEffect(() => {
    if (formData.message) setMessageError('');
  }, [formData.message]);

  const handleSubmit = async (reCaptchaToken: string) => {
    setFeedback('');
    //Form verification
    if (!selectedCategory)
      setCategoryError(validationMessages.categoryRequired);
    if (!formData.name) setNameError(validationMessages.nameRequired);
    if (!formData.email) setEmailError(validationMessages.emailRequired);
    if (!formData.phone) setPhoneError(validationMessages.phoneRequired);
    if (!formData.message) setMessageError(validationMessages.messageRequired);

    if (isNaN(Number(formData.phone))) {
      setPhoneError(validationMessages.phone);
      return;
    }
    if (formData.phone !== '' && formData.phone.length < 8) return;
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.message
    ) {
      return;
    }

    //Form submission
    try {
      setLocalLoading(true);
      const data = {
        category: selectedCategory,
        email: formData.email,
        name: formData.name,
        phone: formData.phone,
        message: formData.message,
        reCaptchaToken: reCaptchaToken,
      };
      setFormData(data);
      await postHaloSubmission(data);
      setFormSubmitted(true);
    } catch (e) {
      console.error(e);
      setFormSubmitted(false);
      setFeedback(validationMessages.error);
    } finally {
      setLocalLoading(false);
      appInsights.trackEvent({ name: '"Send inn skjema" button clicked' });

      // State resetting
      const data = {
        category: '',
        email: '',
        name: '',
        phone: '',
        message: '',
        reCaptchaToken: reCaptchaToken,
      };
      setFormData(data);
    }
  };

  const handleBackToForm = () => {
    setFormSubmitted(false);
    appInsights.trackEvent({ name: '"Tilbake til skjema" button clicked' });
  };

  const ReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('contactForm');
      handleSubmit(token);
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    return (
      <div className={styles.sendContactForm}>
        <StyledButton
          logo={true}
          onClick={handleReCaptchaVerify}
          label='Send kontaktskjema'
        />
      </div>
    );
  };

  return (
    <div id='ContactSection' className={styles.contactSection}>
      <ThemeProvider theme={MainTheme}>
        <div className={styles.content}>
          <div>
            <p className={styles.title}>Kontakt oss ^</p>
          </div>
          <div>
            <p className={styles.underTitle}>
              Obligatoriske felter er merket med *
            </p>
          </div>

          {!localLoading ? (
            !formSubmitted ? (
              <form>
                <div className={styles.contactForm}>
                  <CssTextField
                    className={styles.textField}
                    id='outlined-select-currency'
                    select
                    required
                    label='Hva kan vi hjelpe deg med?'
                    value={selectedCategory}
                    onChange={(e) => handleChange(e, 'category')}
                    error={categoryError !== ''}
                    helperText={categoryError}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </CssTextField>
                  <CssTextField
                    className={styles.textField}
                    required
                    id='outlined-required'
                    label='E-post'
                    inputProps={{ maxLength: 100 }}
                    error={emailError !== ''}
                    helperText={emailError}
                    value={formData.email}
                    onChange={(e) => handleChange(e, 'email')}
                    sx={(theme) => {
                      console.log(theme);
                      return {
                        '& .MuiInputBase-root': {
                          borderColor: theme.palette.error.main,
                        },
                      };
                    }}
                    // sx={{ color: 'error.main' }}
                  />
                  <CssTextField
                    className={styles.textField}
                    required
                    id='outlined-required'
                    label='Navn'
                    inputProps={{ maxLength: 100 }}
                    value={formData.name}
                    onChange={(e) => handleChange(e, 'name')}
                    error={nameError !== ''}
                    helperText={nameError}
                  />
                  <CssTextField
                    className={styles.textField}
                    required
                    id='outlined-required'
                    label='Telefonnummer'
                    type='text'
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      maxLength: 15,
                    }}
                    error={phoneError !== ''}
                    helperText={phoneError}
                    value={formData.phone}
                    onChange={(e) => handleChange(e, 'phone')}
                    color='primary'
                  />
                  <CssTextField
                    className={styles.messageField}
                    required
                    minRows='3'
                    maxRows='3'
                    inputProps={{ maxLength: 1000 }}
                    multiline={true}
                    id='outlined-required'
                    label='Melding'
                    value={formData.message}
                    onChange={(e) => handleChange(e, 'message')}
                    error={messageError !== ''}
                    helperText={messageError}
                  />
                  <div className={styles.contactFormFooter}>
                    <p className={styles.errorMessage}>{feedback}</p>
                    <ReCaptchaComponent />
                  </div>
                </div>
              </form>
            ) : (
              <div className={styles.feedback}>
                <p>{validationMessages.confirm}</p>
                <StyledButton
                  logo={true}
                  onClick={handleBackToForm}
                  label='Tilbake til kontaktskjemaet'
                />
              </div>
            )
          ) : (
            <div className={styles.feedback}>
              <CircularProgress className={styles.progress} />
            </div>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
};

export default ContactForm;
