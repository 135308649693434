import React from 'react';
import styles from './styles.module.scss';

interface IProps {
  img: string;
  h: string;
  p: string;
  hrefText: string;
  href: string;
}

const Card = ({ img, h, p, hrefText, href }: IProps) => (
  <li className={styles.li}>
    <img className={styles.img} src={img} />
    <h3 className={styles.h3}>{h}</h3>
    <p className={styles.p}>{p}</p>
    <a className={styles.a} href={href}>
      {hrefText}
    </a>
  </li>
);

export default Card;
