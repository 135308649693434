import React from 'react';
import styles from './styles.module.scss';

import Olkweb4Header from './Header/Olkweb4Header';
import Hero from './components/Hero';
import News from './components/News';
import DevPhase from './components/DevPhase';
import MoreAbout from './components/MoreAbout';
import Team from './components/Team';
import Questions from './components/Questions';
import ContactForm from './components/ContactForm';
import Olkweb4Footer from './Footer/Olkweb4Footer';

import useWindowDimensions from '../../services/useWindowDimensions';
import Laptop from './components/Laptop';

const Olkweb4Page = (): JSX.Element => {
  const isMobile = useWindowDimensions().width <= 550;

  return (
    <div className={styles.page}>
      <Olkweb4Header />
      <Hero />
      <News />
      <DevPhase />
      <MoreAbout />
      <Laptop show={isMobile} />
      <Team />
      <Questions />
      <ContactForm />
      <Olkweb4Footer />
    </div>
  );
};

export default Olkweb4Page;
