import React from 'react';
import styles from './styles.module.scss';
import Olkweb4Header from '../Olkweb4Page/Header/Olkweb4Header';
import Olkweb4HeaderAndFrontImage from '../../components/Olkweb4HeaderAndFrontImage/Olkweb4HeaderAndFrontImage';
import ArticleFooter from '../../components/ArticleFooter/ArticleFooter';
import ArticleUserExperience from '../../utils/ArticleImages/ArticleUserExperience.png';
import ArticleUserExperienceMobile from '../../utils/ArticleImages/ArticleUserExperienceMobil.png';
import useWindowDimensions from '../../services/useWindowDimensions';

const Olkweb4UserExperiencePage: React.FC = () => {
  const isMobile = useWindowDimensions().width <= 550;

  const renderImage = () => {
    if (isMobile) {
      return ArticleUserExperienceMobile;
    } else {
      return ArticleUserExperience;
    }
  };

  return (
    <div className={styles.articleContainer}>
      <Olkweb4Header />
      <div className={styles.olkweb4ArticleFront}>
        <div className={styles.center}>
          <Olkweb4HeaderAndFrontImage
            headline='En versjon med brukeren i sentrum ^'
            imgsrc={renderImage()}
          />
        </div>
      </div>
      <div className={styles.articleTextContainer}>
        <div className={styles.articleContent}>
          <div>
            <p className={styles.boldText}>
              I prosessen med å oppgradere Olkweb til en ny versjon er det
              viktig for oss at vi forbedrer Olkweb på en slik måte at brukerne
              får en enklere arbeidshverdag.
            </p>
          </div>
          <div>
            <p>
              For å kunne oppnå dette målet har vi fra starten av prosessen
              involvert ansatte og lærlinger fra opplæringskontorer og
              bedrifter, slik at vi får kartlagt deres behov. Gjennom
              spørreundersøkelser og intervjuer har vi fått tilbakemeldinger om
              hva brukerne er fornøyd med i dagens Olkweb, og hvilke funksjoner
              vi kan forbedre.
            </p>
          </div>
          <div>
            <p>
              Siden det å snakke med reelle brukere av systemet er viktig for
              oss, er vi alltid på let etter personer som vil dele sine tanker
              og meninger med oss. Ønsker du å stille til intervju eller
              spørreundersøkelser,{' '}
              <a
                className={styles.mail}
                href='https://forms.office.com/Pages/ResponsePage.aspx?id=Bxuf4XtVSkm58D4OirvAT9RPRy4IfYBPlG_4ghEcZN1UNTdXU1ZETkFSS1BMOUZBMU45SzRONVBUUyQlQCN0PWcu'
              >
                fyll ut kontaktskjemaet her
              </a>
              .
            </p>
          </div>
          <div>
            <h2>Bruker- og betatester</h2>
            <p>
              I tillegg til å kartlegge brukernes behov, utfører vi jevnlig
              brukertester av det nye designet. Ved å la reelle brukere teste
              prototyper kan vi validere om den nye løsningen vil fungere som
              forventet og møter ønskene til brukerne. På denne måten kan vi
              underveis i prosessen finjustere brukergrensesnittet, slik at
              løsningen blir mer brukervennlig og intuitiv.
            </p>
          </div>
          <div>
            <p>
              Utover våren og sommeren vil vi også åpne deler av den nye
              løsningen for betatester. På denne måten får reelle brukere tester
              mer realistiske deler av løsningen, slik at vi kan gjøre flere
              tilpasninger før vi lanserer produktet i slutten av året.
            </p>
          </div>
          <div className={styles.betaTestList}>
            <p>
              Ønsker du å delta som betatester send en e-post til{' '}
              <a
                className={styles.mail}
                href='mailto:kundeservice@arribatec.com'
              >
                kundeservice@arribatec.com
              </a>{' '}
              med følgende informasjon:
            </p>
            <ul>
              <li>
                <p>Jeg ønsker å bli betatester for Olkweb 4.0</p>
              </li>
              <li>
                <p>Ditt navn</p>
              </li>
              <li>
                <p>
                  Hvilken rolle vil du teste i Olkweb? (Lærling - Veileder -
                  Opplæringskonsulent)
                </p>
              </li>
              <li>
                <p>Hvilket opplæringskontor tilhører du?</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ArticleFooter />
    </div>
  );
};
export default Olkweb4UserExperiencePage;
