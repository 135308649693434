import { makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles({
  sectionheadertext: {
    fontSize: '20px',
    fontFamily: 'Almarena-DisplayLight',
    marginLeft: '22px',
    textDecorationLine: 'underline',
  },
  mainSectionButtonLink: {
    fontSize: '30px',
    fontFamily: 'Almarena-DisplayLight',
    textDecorationLine: 'underline',
    lineHeight: '40px',
  },
  headerTitle: {
    fontSize: '100px',
    fontFamily: 'Almarena-DisplayLight',
    marginBottom: 0,
    lineHeight: 0.8,
  },
  olkStatText: {
    fontSize: '20px',
    fontFamily: 'Inter',
  },
  olkStatNumber: {
    fontSize: '70px',
    lineHeight: 1,
    fontFamily: 'Almarena-DisplayLight',
  },
  mainDesc: {
    fontSize: '28px',
    fontFamily: 'Inter-Light',
  },
  section2header: {
    fontSize: '45px',
    fontFamily: 'Almarena-DisplayLight',
    marginTop: '110px',
    marginBottom: '90px',
  },
  aboutSubTitle: {
    fontSize: '30px',
    fontFamily: 'Almarena-DisplayLight',
  },
});

export default jsStyles;
