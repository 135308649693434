import React from 'react';
import styles from './styles.module.scss';

interface Olkweb4HeaderAndFrontImageProps {
  headline: string;
  imgsrc: string;
  altText?: string;
}

const Olkweb4HeaderAndFrontImage: React.FC<Olkweb4HeaderAndFrontImageProps> = ({
  headline,
  imgsrc,
  altText,
}) => {
  return (
    <div className={styles.olkweb4HeaderAndFrontImageContainer}>
      <div className={styles.header}>
        <div className={styles.headerLine} />
        <h1>{headline}</h1>
        <div className={styles.headerLine} />
      </div>
      <>
        <img src={imgsrc} alt={altText} />
      </>
    </div>
  );
};
export default Olkweb4HeaderAndFrontImage;
