/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { IFormData, IFormData2 } from '../interfaces/ContactForm';
import { IDeleteUserForm } from '../interfaces/DeleteUserForm';
import { IStats } from '../interfaces/OlkwebStats';

const Url = 'https://api-p-olkwebnettside.azurewebsites.net/api';
//const Url = process.env.REACT_APP_API_HOST as string;
// Using url from the different .env files.

interface IAPIConfig {
  baseURL: string;
}

interface IAnswerPayload {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: string | unknown;
}

type IHaloSubmission = {
  subject: string;
  body: string;
  email: string;
  name: string;
  phonenumber: string;
  reCaptchaToken: string;
};

type IHaloSubmission2 = {
  subject: string;
  body: string;
  email: string;
  name: string;
  reCaptchaToken: string;
};

type IHaloDeleteUser = {
  subject: string;
  body: string;
  email: string;
  name: string;
  phonenumber: string;
  reCaptchaToken: string;
};

const API: IAPIConfig = {
  baseURL: Url,
};

const postHaloSubmission = (input: IFormData) => {
  const data: IHaloSubmission = {
    subject: input.category,
    body: input.message,
    email: input.email,
    name: input.name,
    phonenumber: input.phone.toString(),
    reCaptchaToken: input.reCaptchaToken,
  };
  return axios.post<IAnswerPayload>('/halo/ticket', data, {
    ...API,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const postHaloSubmission2 = (input: IFormData2) => {
  const data: IHaloSubmission2 = {
    subject: 'Spørsmål ang. Olkweb 4',
    body: input.message,
    email: input.email,
    name: input.name,
    reCaptchaToken: input.reCaptchaToken,
  };
  return axios.post<IAnswerPayload>('/halo/ticket', data, {
    ...API,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const postHaloDeleteUser = (input: IDeleteUserForm) => {
  const data: IHaloDeleteUser = {
    subject: 'Bruker ber om sletting av konto',
    body: input.message,
    email: input.email,
    name: input.name,
    phonenumber: input.phone.toString(),
    reCaptchaToken: input.reCaptchaToken,
  };
  return axios.post<IAnswerPayload>('/gdpr/ticket', data, {
    ...API,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getOlkwebData = () => {
  return axios.get<IStats>('/olkweb/stats', {
    ...API,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export {
  postHaloSubmission,
  postHaloSubmission2,
  postHaloDeleteUser,
  getOlkwebData,
};
