import { makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles({
  text: {
    color: 'var(--Black, #000)',
    fontSize: '20px !important',
    fontFamily: 'Inter',
    fontWeight: 400,
    marginLeft: '5px',
    lineHeight: '28px',
    textDecorationLine: 'none',
  },
});

export default jsStyles;
