import React from 'react';
import { ReactComponent as ArribatecLogo } from '../../utils/icons/LogoFooter.svg';
import styles from './styles.module.scss';

const Footer = (): JSX.Element => {
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <ArribatecLogo fill='white' className={styles.logo} />
      </div>
    </div>
  );
};

export default Footer;
