import React from 'react';
import styles from './styles.module.scss';
import NewsImage from '../../utils/pictures/NyBrukerflate.png';
import { ButtonLink } from '..';
import { Link } from 'react-router-dom';

const MainPageNewsSection = (): JSX.Element => {
  return (
    <>
      <div className={styles.mainPageNewsSection}>
        <div className={styles.container}>
          <div className={styles.newsHeadLine}>
            <h1>Nyheter ^</h1>
          </div>
          <div className={styles.newsContents}>
            <div className={styles.newsImageContainer}>
              <Link to={'news'}>
                <img src={NewsImage} alt='' />
              </Link>
            </div>
            <div className={styles.newsText}>
              <h2>Vi introduserer nyskapende web-app</h2>
              <h3>16.08.23</h3>
              <div>
                <p>
                  Ny brukerflate for lærlinger er nå i betatest hos utvalgte
                  opplæringskontor. Dette markerer det første steget i en
                  omfattende utviklingsprosess for Olkweb. Den nye brukerflaten
                  er web-basert som før, og fungerer på både pc, nettbrett og
                  mobil. Vi har designet den med fokus på å forbedre
                  opplevelsen, og ser frem til å motta verdifulle
                  tilbakemeldinger fra brukerne i betatestfasen. Etter planen
                  vil den nye brukerflaten åpnes for alle lærlinger senere i
                  høst. Dette gir dem muligheten til å dra nytte av de
                  forbedrede funksjonene og den forbedrede opplevelsen som den
                  nye brukerflaten bringer med seg. Dette prosjektet
                  representerer vår forpliktelse til å kontinuerlig forbedre og
                  tilpasse våre tjenester for å møte brukernes behov. Vi setter
                  stor pris på engasjementet og støtten fra våre brukere, og ser
                  frem til å dele ytterligere oppdateringer og forbedringer i
                  tiden som kommer. Sammen tar vi det første steget mot en mer
                  optimal og brukervennlig opplevelse.
                </p>
              </div>
              <div>
                <ButtonLink label='Les flere nyheter' link='news' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPageNewsSection;
