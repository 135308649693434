import { makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles({
  heading: {
    fontSize: '45px',
    fontFamily: 'Almarena-DisplayLight',
    marginTop: 24,
    lineHeight: 1.5,
    '@media screen and (max-width: 550px)': {
      fontSize: '30px',
    },
  },
  subheading: {
    fontSize: '30px',
    fontFamily: 'Almarena-DisplayLight',
    marginTop: 12,
    lineHeight: 2,
    '@media screen and (max-width: 550px)': {
      fontSize: '25px',
    },
  },
  subheading2: {
    fontSize: '25px',
    fontFamily: 'Almarena-DisplayLight',
    marginTop: 12,
    marginBottom: 0,
    lineHeight: 2,
    '@media screen and (max-width: 550px)': {
      fontSize: '20px',
    },
  },
  body: {
    fontSize: '20px',
    fontFamily: 'Inter',
    marginTop: 0,
    marginBottom: 12,
    lineHeight: 1.4,
    '@media screen and (max-width: 550px)': {
      fontSize: '15px',
    },
  },
});

export default jsStyles;
