import React from 'react';
import styles from './styles.module.scss';
import Collapse from '@mui/material/Collapse';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

interface ExpandMoreProps extends ButtonProps {
  expand: boolean;
  label: string;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, label, ...other } = props;
  return (
    <>
      {expand ? (
        <Button
          style={{ backgroundColor: 'transparent', padding: 0 }}
          disableRipple={true}
          {...other}
        >
          <p className={styles.ButtonText}>Lukk {label}</p>
        </Button>
      ) : (
        <Button
          style={{ backgroundColor: 'transparent', padding: 0 }}
          disableRipple={true}
          {...other}
        >
          <p className={styles.ButtonText}>Les mer om {label}</p>
        </Button>
      )}
    </>
  );
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AboutSection = (): JSX.Element => {
  const appInsights = useAppInsightsContext();
  const [documentationExpanded, setDocumentationExpanded] =
    React.useState(false);
  const [administrationExpanded, setAdministrationExpanded] =
    React.useState(false);
  const [followupExpanded, setFollowupExpanded] = React.useState(false);
  const [reportsExpanded, setReportsExpanded] = React.useState(false);

  const trackDocCollapse = () => {
    appInsights.trackEvent({ name: 'About Olkweb clicked (Menu item)' });
  };
  const trackFollowupCollapse = () => {
    appInsights.trackEvent({ name: 'Mobile app clicked (Menu item)' });
  };
  const trackAdminCollapse = () => {
    appInsights.trackEvent({ name: 'Contact clicked (Menu item)' });
  };
  const trackGuideCollapse = () => {
    appInsights.trackEvent({ name: 'User guide clicked (Menu item)' });
  };

  const handleExpandClick = (
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>,
    expanded: boolean,
    category: string,
  ) => {
    setExpanded(!expanded);
    if (category == 'documentation') trackDocCollapse();
    if (category == 'followup') trackFollowupCollapse();
    if (category == 'administration') trackAdminCollapse();
    if (category == 'reports') trackGuideCollapse();
  };

  return (
    <div id='AboutSection' className={styles.section2}>
      <div className={styles.sectionContent}>
        <div>
          <p className={styles.section2header}>Om Olkweb ^</p>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.aboutBox}>
            <div className={styles.aboutTitleBox}>
              <div className={styles.titleLine} />
              <p className={styles.aboutTitle}>Dokumentasjon ^</p>
              <div className={styles.titleLine} />
            </div>
            <div className={styles.aboutContent}>
              <p className={styles.shortAboutText}>
                Med egen opplæringsbok kan lærlingen enkelt produsere
                dokumentasjon.
              </p>
              <Collapse in={documentationExpanded} timeout='auto' unmountOnExit>
                <ul className={styles.list}>
                  <li className={styles.listText}>
                    Automatisk nedlasting av læreplaner fra UDIR for
                    dokumentasjon.
                  </li>
                  <li className={styles.listText}>
                    Dokumenter enkelt på læreplanmål på pc, Mac, nettbrett eller
                    mobil.
                  </li>
                  <li className={styles.listText}>
                    Underveis-dokumentasjon med løpende tilbakemeldinger fra
                    konsulent eller bedrift.
                  </li>
                  <li className={styles.listText}>
                    Dokumenter enkelt på flere læreplanmål samtidig.
                  </li>
                  <li className={styles.listText}>
                    Støtter gjennomgående dokumentasjon.
                  </li>
                  <li className={styles.listText}>
                    Støtter bilder og video for bedre dokumentering av arbeid.
                  </li>
                  <li className={styles.listText}>
                    Timeregistrering tilknyttet dokumentering ved behov.
                  </li>
                </ul>
              </Collapse>
              <ExpandMore
                label='dokumentasjon'
                expand={documentationExpanded}
                onClick={() =>
                  handleExpandClick(
                    setDocumentationExpanded,
                    documentationExpanded,
                    'documentation',
                  )
                }
                aria-expanded={documentationExpanded}
                aria-label='show more'
              />
            </div>
          </div>
          <div className={styles.aboutBox}>
            <div className={styles.aboutTitleBox}>
              <div className={styles.titleLine} />
              <p className={styles.aboutTitle}>Oppfølging ^</p>
              <div className={styles.titleLine} />
            </div>
            <div className={styles.aboutContent}>
              <p className={styles.shortAboutText}>
                Olkweb er er tilpasset slik at faglig leder enkelt kan følge opp
                sine lærlinger.
              </p>
              <Collapse in={followupExpanded} timeout='auto' unmountOnExit>
                <ul className={styles.list}>
                  <li className={styles.listText}>
                    Egenvurdering for lærlingen.
                  </li>
                  <li className={styles.listText}>
                    Oppbygging av egne vurderingskriterier.
                  </li>
                  <li className={styles.listText}>
                    Enkel gjennomføring av halvårssamtaler.
                  </li>
                  <li className={styles.listText}>
                    Arkiv for vurderinger gjennom lærlingperioden.
                  </li>
                  <li className={styles.listText}>
                    Måling av progresjon underveis i lærlingtiden.
                  </li>
                </ul>
              </Collapse>
              <ExpandMore
                label='oppfølging'
                expand={followupExpanded}
                onClick={() =>
                  handleExpandClick(
                    setFollowupExpanded,
                    followupExpanded,
                    'followup',
                  )
                }
                aria-expanded={followupExpanded}
                aria-label='show more'
              />
            </div>
          </div>
          <div className={styles.aboutBox}>
            <div className={styles.aboutTitleBox}>
              <div className={styles.titleLine} />
              <p className={styles.aboutTitle}>Administrasjon ^</p>
              <div className={styles.titleLine} />
            </div>
            <div className={styles.aboutContent}>
              <p className={styles.shortAboutText}>
                Med Olkweb kan du beregne tilskudd for lærlinger og utbetaling
                for bedrifter.
              </p>
              <Collapse
                in={administrationExpanded}
                timeout='auto'
                unmountOnExit
              >
                <ul className={styles.list}>
                  <li className={styles.listText}>
                    Enkel oppfølging av lærlingen og bedriftene.
                  </li>
                  <li className={styles.listText}>
                    Automatisk generering av lærlingkontrakter.
                  </li>
                  <li className={styles.listText}>
                    Oppmelding til fag/svenneprøve.
                  </li>
                  <li className={styles.listText}>
                    Journalføring, notatføring og dokumentarkiv.
                  </li>
                </ul>
              </Collapse>
              <ExpandMore
                label='administrasjon'
                expand={administrationExpanded}
                onClick={() =>
                  handleExpandClick(
                    setAdministrationExpanded,
                    administrationExpanded,
                    'administration',
                  )
                }
                aria-expanded={administrationExpanded}
                aria-label='show more'
              />
            </div>
          </div>
          <div className={styles.aboutBox}>
            <div className={styles.aboutTitleBox}>
              <div className={styles.titleLine} />
              <p className={styles.aboutTitle}>Rapporter ^</p>
              <div className={styles.titleLine} />
            </div>
            <div className={styles.aboutContent}>
              <p className={styles.shortAboutText}>
                Olkweb lar deg hente ut rapporter basert på lærlinger, bedrifter
                og dokumentasjon.
              </p>
              <Collapse in={reportsExpanded} timeout='auto' unmountOnExit>
                <ul className={styles.list}>
                  <li className={styles.listText}>
                    Egne tilpassede rapporter med støtte for PDF, excel, csv.
                  </li>
                  <li className={styles.listText}>
                    Filter og utvalgskriterier på rapporter.
                  </li>
                  <li className={styles.listText}>
                    Grafisk fremstilling av historiske data.
                  </li>
                  <li className={styles.listText}>
                    Tilskuddsrapporter med fordeling og budsjettmuligheter.
                  </li>
                </ul>
              </Collapse>
              <ExpandMore
                label='rapporter'
                expand={reportsExpanded}
                onClick={() =>
                  handleExpandClick(
                    setReportsExpanded,
                    reportsExpanded,
                    'reports',
                  )
                }
                aria-expanded={reportsExpanded}
                aria-label='show more'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
