import { makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles({
  name: {
    color: 'var(--Black, #000)',
    fontFamily: 'Inter-Medium',
    fontSize: '19.535px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '27.349px',
  },
  jobTitle: {
    width: '18ch',
    color: 'var(--dark-green-800, #20333D)',
    fontFamily: 'Inter',
    fontSize: '15.628px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '27.349px',
  },
});

export default jsStyles;
