import React from 'react';
import styles from './styles.module.scss';
import Olkweb4Header from '../Olkweb4Page/Header/Olkweb4Header';
import ArticleFooter from '../../components/ArticleFooter/ArticleFooter';
import Olkweb4HeaderAndFrontImage from '../../components/Olkweb4HeaderAndFrontImage/Olkweb4HeaderAndFrontImage';
import ArticleSecurity from '../../utils/ArticleImages/ArticleSecurity.png';
import ArticleSecurityMobile from '../../utils/ArticleImages/ArticleSecurityMobile.png';
import ArticleSecurityByDesign from '../../utils/ArticleImages/ArticleSecurityByDesign.png';
import useWindowDimensions from '../../services/useWindowDimensions';

const Olkweb4SecurityPage: React.FC = () => {
  const isMobile = useWindowDimensions().width <= 550;

  const renderImage = () => {
    if (isMobile) {
      return ArticleSecurityMobile;
    } else {
      return ArticleSecurity;
    }
  };

  return (
    <div className={styles.articleContainer}>
      <Olkweb4Header />
      <div className={styles.olkweb4ArticleFront}>
        <div className={styles.center}>
          <Olkweb4HeaderAndFrontImage
            headline='Sikkerhet som en sentral del ^'
            imgsrc={renderImage()}
          />
        </div>
      </div>
      <div className={styles.articleTextContainer}>
        <div className={styles.articleContent}>
          <div>
            <p className={styles.boldText}>
              Sikkerhet i løsningen er en sentral del i utviklingsprosessen av
              Olkweb 4. «Security by Design» er et utviklingsprinsipp med en
              proaktiv tilnærming til sikkerhet. Beskyttelse mot trusler og
              risiko kartlegges og implementeres i systemarkitekturen helt fra
              starten av. Slik reduseres mulige sårbarheter som kan utnyttes av
              en ondsinnet aktør og man bygger en solid grunnmur for å beskytte
              sensitiv data. Dette er et av de førende prinsippene vi arbeider
              etter i Arribatec.
            </p>
          </div>
          <div>
            <p>
              Som et ledd i «Security by Design» benytter vi en metodikk som
              bygger på «Secure Software Development Life Cycle» (SSDLC). SSDLC
              vektlegger sikkerhet i alle faser av utviklingsprosessen, fra krav
              og design, via utvikling og testing til leveranse og
              implementasjon. Dette er illustrert i figuren under.
            </p>
          </div>
          <div className={styles.imgContainer}>
            <img
              src={ArticleSecurityByDesign}
              alt='SSDLC-metoden og dens fem faser: Krav, design, utvikling, produksjonssetting og testing.'
            />
          </div>
          <div>
            <h2>Krav og design</h2>
            <p>
              I krav- og designfasen betyr SSDLC en systematisk kartlegging av
              sikkerhetskravene og implementering av disse i systemdesign og
              arkitektur. For nye Olkweb innebærer dette kartlegging av krav
              rundt bruker-autentisering og -autorisering, og krav rundt
              sikkerhet i filhåndtering i systemet, for å nevne noen områder. I
              designfasen bruker vi også «Threat Modeling» som metode. Her
              kartlegges mulige trusler mot systemet og tiltak for å avverge
              eller begrense disse. Slik kvalitetssikres de valgene som er tatt
              i krav- og designarbeidet, samtidig som vi får avdekket områder
              som ikke er dekket godt nok i de valgte sikkerhetsløsningene.
            </p>
          </div>
          <div>
            <h2>Utvikling</h2>
            <p>
              Olkweb 4 bygges med moderne utviklingsspråk og rammeverk med god
              innebygd støtte for sikkerhet. Dette alene gir ingen garanti for
              sikkerheten i en applikasjon. Vi jobber kontinuerlig med å bygge
              sikkerhetskunnskap og forståelse hos utviklerne, og gjør dem
              dermed i stand til å ta gode valg i utviklingsarbeidet. Blant
              annet har vi fokus på sårbarheter identifisert i OWASP Top 10.
              Dette er en liste over de 10 største sårbarhetene som kan oppstå i
              en webapplikasjon, og regnes som en bransjestandard over de
              viktigste sårbarhetene i webapplikasjoner. I tillegg til benytter
              vi statisk kodeanalyse (SAST) for å sikre kvalitet på kildekoden i
              utviklingsfasen. Dette er en automatisk skanning av koden som
              varsler ved mulige sårbarheter og svakheter i kildekoden. Koden
              som settes i produksjon vil dermed være uten sårbarheter, samtidig
              som vi bevarer kodekvalitet over tid.
            </p>
          </div>
          <div>
            <h2>Test</h2>
            <p>
              Utover standard utviklingsverktøy som Code Review, der utviklerne
              kontrollerer og kvalitetssikrer hverandres kode, og Unit Testing,
              automatisk testing av funksjonalitet og sikkerhet i systemet, vil
              vi også benytte dynamisk sikkerhetstesting (DAST). DAST er
              automatisk testing av en kjørende applikasjon, som simulerer
              angrep mot applikasjonen i en aktiv tilstand. Der SAST analyserer
              kildekoden før den eksekveres, vil DAST forsøke å finne
              sårbarheter i kjørende kode. Dynamiske sikkerhetstester før
              produksjonssetting av kode identifiserer og adresserer sårbarheter
              som ikke er behandlet av sikkerhetsmekanismer implementert i
              applikasjonen eller ikke fanget opp i tidligere testing. I en stor
              og kompleks applikasjon som Olkweb vil manuell testing vanskelig
              dekke absolutt alle deler av applikasjonen. DAST øker denne
              testdekningen betraktelig.
            </p>
          </div>
          <div>
            <h2>Produksjonssetting</h2>
            <p>
              I produksjonssettingsfasen brukes automatiske verktøy for bygging
              og utrulling av applikasjonen. Dette sikrer at oppsettet av nye
              Olkweb gjøres på en enhetlig og standardisert måte og reduserer
              risikoen for at feil i oppsettet av applikasjonen skaper
              sårbarheter. I denne fasen utføres også skanning av
              tredjepartskomponenter som er i bruk i nye Olkweb. Alle slike
              komponenter kvalitetssikres før de tas i bruk, men i denne
              skanningen vil eventuelle nye sårbarheter fanges opp, og gjør oss
              i stand til å sette inn tiltak før en ny versjon settes i
              produksjon. Vi bygger også opp en såkalt «Software Bill Of
              Materials» (SBOM) for hver versjon av Olkweb. Dette er en
              totaloversikt over alle komponenter og versjoner av disse som
              inngår i applikasjonen. Det gjør oss i stand til å enkelt vurdere
              om en rapportert sårbarhet i en komponent rammer vår applikasjon.
            </p>
          </div>
          <div>
            <h2>Veien videre</h2>
            <p>
              Med «Security by design» som førende prinsipp og SSDLC som
              utviklingsmetodikk bygges sikkerhet inn i nye Olkweb fra start og
              har dermed god beskyttelse av sensitiv data. Arbeidet med
              sikkerhet i en applikasjon er aldri «ferdig». Ondsinnede aktører
              ser konstant etter nye måter å utnytte og trenge inn i en
              applikasjon, og nye sårbarheter vil oppstå. Med vår tilnærming og
              metodikk bygger vi et godt fundament for å ivareta sikkerheten
              gjennom videreutvikling og vedlikehold av Olkweb 4, slik at
              brukere og data beskyttes også i fremtiden.
            </p>
          </div>
        </div>
      </div>
      <ArticleFooter />
    </div>
  );
};
export default Olkweb4SecurityPage;
