import { createTheme } from '@material-ui/core/styles';

const MainTheme = createTheme({
  palette: {
    primary: {
      main: '#414141',
    },
    secondary: {
      main: '#f7f5f2',
      light: '#fff',
    },
    error: {
      main: '#ac1a15 !important',
    },
  },
});

export default MainTheme;
