import React from 'react';
import styles from './styles.module.scss';
import Section from './Section';
import Laptop from '../../../../utils/pictures/Olkweb4Page/Laptop.png';
import Divisor from './Divisor';

const Questions = () => (
  <section className={styles.section} id='Questions'>
    <div className={styles.div}>
      <article className={styles.article}>
        <h2 className={styles.h2}>Ofte stilte spørsmål</h2>
        <div className={styles.sections}>
          <Section heading='Hva betyr overgangen for meg som kunde?'>
            {[
              'Overgangen fra Olkweb 3.0 til 4.0 vil først og fremst gi deg et enklere, raskere, mer moderne og sikrere system.',
              'I overgangen vil vi ta med all nødvendig historikk og brukere fra dagens løsning til ny løsning.',
            ]}
          </Section>
          <Divisor />
          <Section heading='Hvordan blir overgangen til Olkweb 4?'>
            {[
              'Vi har stor forståelse for at eksisterende kunder ønsker å gjennomføre overgangen fra Olkweb 3.0 til 4.0 når det passer dere best. Vi vet at hverdagen er travel og tidspunktet må koordineres. For å imøtekomme dette på best måte vil vi gjennomføre overgangen puljevis.',
              'I løpet av høsten vil vi dele info om puljeinndeling, og gi mulighet for å påvirke når overgangen skal skje. Dette gjøres i tett dialog mellom oss og deg som kunde.',
            ]}
          </Section>
          <Divisor />
          <Section heading='Vil jeg få opplæring i Olkweb 4?'>
            {[
              'Selvsagt! For nye kunder vil et kurs fortsatt være en del av oppstartsaktiviteten.',
              'Det vil bli laget en ny brukermanual, videoer og strukturert info om det nye i Olkweb 4.0. I tillegg vil vi legge opp til webinarer på tidspunkt som harmonerer med tidspunkt for overgangen til ny løsning.',
            ]}
          </Section>
          <Divisor />
          <Section heading='Hva skjer med Olkweb 3.0 når Olkweb 4.0 er lansert?'>
            {[
              'Vi planlegger å avslutte support av Olkweb 3 før utløpet av 2025. All nødvendig data blir overført til ny versjon.',
            ]}
          </Section>
          <Divisor />
        </div>
      </article>
      <img
        className={styles.img}
        src={Laptop}
        alt='En bærbar PC og mobil av vurderingssamtale i Olkweb'
      />
    </div>
  </section>
);

export default Questions;
