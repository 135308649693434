import { makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles({
  header: {
    fontSize: '45px',
    fontFamily: 'Almarena-DisplayLight',
    marginBottom: 5,
    lineHeight: 0.8,
  },
  body: {
    fontSize: '20px',
    fontFamily: 'Inter',
    marginBottom: 0,
    lineHeight: 1.2,
  },
});

export default jsStyles;
