import React, { useEffect, useState, useCallback } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { CircularProgress } from '@mui/material';
import { postHaloSubmission } from '../../../../services/Api';
import { IFormData } from '../../../../interfaces/ContactForm';
import StyledButton from '../../../../components/StyledButton/StyledButton2';
import styles from './styles.module.scss';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CssTextField from '../../../../components/CssTextField/CssTextField2';

const ContactForm = (): JSX.Element => {
  const appInsights = useAppInsightsContext();
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>('');
  const [hasInteracted, setHasInteracted] = useState(false);
  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [formData, setFormData] = useState<IFormData>({
    email: '',
    name: '',
    message: '',
    reCaptchaToken: '',
    phone: 'Ikke oppgitt',
    category: 'Melding fra Olkweb 4-side',
  });

  const validationMessages = {
    categoryRequired: 'Benytt et av valgene i listen.',
    nameRequired: 'Fyll inn navn.',
    phoneRequired: 'Fyll inn telefonnummer.',
    phone: 'Telefonnummer kan kun innholde tall.',
    phoneLength: 'Fyll inn telefonnummer med 8 sifre.',
    emailRequired: 'Fyll inn gyldig e-postadresse.',
    messageRequired: 'Skriv inn melding',
    confirm: 'Takk for din henvendelse.',
    error:
      'Det oppsto et problem, prøv igjen eller send en e-post til kundeservice@arribatec.com',
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {
    const data = {
      email: formData.email,
      name: formData.name,
      message: formData.message,
      reCaptchaToken: formData.reCaptchaToken,
      phone: 'Ikke oppgitt',
      category: 'Melding fra Olkweb 4-side',
    };
    if (field === 'name') data.name = e.target.value;
    if (field === 'email') data.email = e.target.value;
    if (field === 'message') data.message = e.target.value;
    if (!hasInteracted) {
      setHasInteracted(true);
    }
    setFormData(data);
  };

  useEffect(() => {
    if (formData.name) setNameError('');
  }, [formData.name]);

  useEffect(() => {
    if (hasInteracted) {
      if (formData.email === '') {
        setEmailError('');
      } else if (formData.email.includes('@') && formData.email.includes('.')) {
        setEmailError('');
      } else {
        setEmailError(validationMessages.emailRequired);
      }
    }
  }, [formData.email, hasInteracted]);

  useEffect(() => {
    if (formData.message) setMessageError('');
  }, [formData.message]);

  const handleSubmit = async (reCaptchaToken: string) => {
    setFeedback('');
    if (!formData.name) setNameError(validationMessages.nameRequired);
    if (!formData.email) setEmailError(validationMessages.emailRequired);
    if (!formData.message) setMessageError(validationMessages.messageRequired);

    if (!formData.name || !formData.email || !formData.message) {
      return;
    }

    try {
      setLocalLoading(true);
      const data = {
        email: formData.email,
        name: formData.name,
        message: formData.message,
        reCaptchaToken: reCaptchaToken,
        phone: 'Ikke oppgitt',
        category: 'Melding fra Olkweb 4-side',
      };
      setFormData(data);
      const res = await postHaloSubmission(data);
      setFormSubmitted(true);
      if (res.status >= 200 && res.status < 300)
        setFormData({
          email: '',
          name: '',
          message: '',
          reCaptchaToken: reCaptchaToken,
          phone: 'Ikke oppgitt',
          category: 'Melding fra Olkweb 4-side',
        });
    } catch (e) {
      setFormSubmitted(false);
      setFeedback(validationMessages.error);
    } finally {
      setLocalLoading(false);
      appInsights.trackEvent({ name: '"Send inn skjema" button clicked' });
    }
  };

  const handleBackToForm = () => {
    setFormSubmitted(false);
    appInsights.trackEvent({ name: '"Tilbake til skjema" button clicked' });
  };

  const ReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        return;
      }

      const token = await executeRecaptcha('contactForm');
      handleSubmit(token);
    }, [executeRecaptcha]);

    return (
      <div className={styles.sendContactForm}>
        <StyledButton
          logo={true}
          onClick={handleReCaptchaVerify}
          label='Send kontaktskjema'
        />
      </div>
    );
  };

  return (
    <div className={styles.contactSection}>
      <div className={styles.content}>
        <div>
          <p className={styles.title}>Har du spørsmål? Ta kontakt med oss!</p>
        </div>
        <div>
          <p className={styles.underTitle}>Alle felter er obligatorisk.</p>
        </div>

        {!localLoading ? (
          !formSubmitted ? (
            <form>
              <div className={styles.contactForm}>
                <CssTextField
                  className={styles.textField}
                  required
                  label='Navn'
                  inputProps={{ maxLength: 100 }}
                  value={formData.name}
                  onChange={(e) => handleChange(e, 'name')}
                  error={nameError !== ''}
                  helperText={nameError}
                />
                <CssTextField
                  className={styles.textField}
                  required
                  label='E-post'
                  inputProps={{ maxLength: 100 }}
                  error={emailError !== ''}
                  helperText={emailError}
                  value={formData.email}
                  onChange={(e) => handleChange(e, 'email')}
                  sx={(theme) => {
                    return {
                      '& .MuiInputBase-root': {
                        borderColor: theme.palette.error.main,
                      },
                    };
                  }}
                />
                <CssTextField
                  className={styles.messageField}
                  required
                  minRows='3'
                  maxRows='3'
                  inputProps={{ maxLength: 1000 }}
                  multiline={true}
                  label='Melding'
                  value={formData.message}
                  onChange={(e) => handleChange(e, 'message')}
                  error={messageError !== ''}
                  helperText={messageError}
                />
                <div className={styles.contactFormFooter}>
                  <p className={styles.errorMessage}>{feedback}</p>
                  <ReCaptchaComponent />
                </div>
              </div>
            </form>
          ) : (
            <div className={styles.feedback}>
              <p>{validationMessages.confirm}</p>
              <StyledButton
                logo={true}
                onClick={handleBackToForm}
                label='Tilbake til kontaktskjemaet'
              />
            </div>
          )
        ) : (
          <div className={styles.feedback}>
            <CircularProgress className={styles.progress} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
