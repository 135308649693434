import React from 'react';
import { NewsHeader, Footer } from '../../components';
import styles from './styles.module.scss';
import NewsSection from '../../components/NewsSection/NewsSection';

const NewsPage = (): JSX.Element => {
  return (
    <div className={styles.newsPage}>
      <NewsHeader />
      <NewsSection />
      <Footer />
    </div>
  );
};

export default NewsPage;
